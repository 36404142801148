import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import Loading from "assets/puff.svg";
//
import "./loader.scss";

interface Props {
  loading: boolean;
}

const Loader: React.FC<Props> = (props) => {
  return (
    <div className={classNames("loader", { "is-visible": props.loading })}>
      <Loading />
    </div>
  );
};

export default Loader;
