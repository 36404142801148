import { Link } from "gatsby";
import React from "react";
import "./footer.component.scss";

interface Props {}

const Footer: React.FC<Props> = (props) => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__inner">
          <div className="footer__left">
            <a href="mailto:hello@rocani.studio">hello@rocani.studio</a>
          </div>
          {/* <div
            className="footer__copy"
            dangerouslySetInnerHTML={{ __html: "Rocani Studio 2022 &copy;" }}
          /> */}
          <div className="footer__legal">
            <Link to="/full-stack-developer">Jobs</Link>
            <Link to="/imprint">Imprint</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
