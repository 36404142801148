import React from "react";
import "./header.component.scss";
import Logo from "assets/logo-solid.svg";
import { Link } from "gatsby";

interface Props {}

const Header: React.FC<Props> = (props) => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__inner">
          <a href="https://www.rocani.studio">
            <Logo className="header-logo" />
          </a>
          <div className="header-links">LINKS</div>
        </div>
      </div>
    </header>
  );
};

export default Header;
