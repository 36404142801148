import React from "react";
import FontFaceObserver from "fontfaceobserver";
import Header from "components/header/header.component";
import "./layout.scss";
import Footer from "components/footer/footer.component";
import Loader from "components/loader/loader";

interface Props {
  children: JSX.Element;
}

const Layout: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isFontLoaded, setIsFontLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isFontLoaded) {
      const font = new FontFaceObserver("proxima-nova-condensed");
      font
        .load()
        .then(() => setIsFontLoaded(true))
        .catch(() => setIsFontLoaded(true));
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFontLoaded]);

  return (
    <div className="layout">
      <Loader loading={isLoading} />
      {!!!isLoading && (
        <>
          <Header />
          <main className="layout-main">{props.children}</main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
